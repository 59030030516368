/**
 * Do not edit directly
 * Generated on Thu, 08 Feb 2024 10:51:27 GMT
 */

export const Primary25: string = '#effdee';
export const Primary50: string = '#e0fee0';
export const Primary75: string = '#cefdd3';
export const Primary100: string = '#c0fdc6';
export const Primary125: string = '#adfcb9';
export const Primary150: string = '#9bf8ad';
export const Primary175: string = '#8af4a2';
export const Primary200: string = '#74ee97';
export const Primary225: string = '#65e98c';
export const Primary250: string = '#56e280';
export const Primary275: string = '#4ae07a';
export const Primary300: string = '#45dc70';
export const Primary325: string = '#41d967';
export const Primary350: string = '#41d560';
export const Primary375: string = '#32d25d';
export const Primary400: string = '#28ce58';
export const Primary425: string = '#02cb57';
export const Primary450: string = '#01c652';
export const Primary475: string = '#00c24c';
export const Primary500: string = '#01bd49';
export const Primary525: string = '#00b544';
export const Primary550: string = '#01ad41';
export const Primary575: string = '#01a53e';
export const Primary600: string = '#019d3b';
export const Primary625: string = '#009439';
export const Primary650: string = '#008b35';
export const Primary675: string = '#018233';
export const Primary700: string = '#007830';
export const Primary725: string = '#016e2d';
export const Primary750: string = '#006529';
export const Primary775: string = '#005e29';
export const Primary800: string = '#005627';
export const Primary825: string = '#004f28';
export const Primary850: string = '#004422';
export const Primary875: string = '#003a1e';
export const Primary900: string = '#002e1a';
export const Primary925: string = '#042315';
export const Primary950: string = '#061610';
export const Primary975: string = '#000c04';
export const Accent25: string = '#fffae7';
export const Accent50: string = '#fff4d1';
export const Accent75: string = '#ffeebb';
export const Accent100: string = '#ffe8a6';
export const Accent125: string = '#ffe392';
export const Accent150: string = '#ffdc80';
export const Accent175: string = '#ffd670';
export const Accent200: string = '#ffcf62';
export const Accent225: string = '#fec946';
export const Accent250: string = '#fdc325';
export const Accent275: string = '#f7bd12';
export const Accent300: string = '#f1b70a';
export const Accent325: string = '#eab109';
export const Accent350: string = '#e4ab0a';
export const Accent375: string = '#dca509';
export const Accent400: string = '#d6a008';
export const Accent425: string = '#ce9a08';
export const Accent450: string = '#c69407';
export const Accent475: string = '#be8d07';
export const Accent500: string = '#b78807';
export const Accent525: string = '#af8206';
export const Accent550: string = '#a77b05';
export const Accent575: string = '#9e7605';
export const Accent600: string = '#956f05';
export const Accent625: string = '#8d6902';
export const Accent650: string = '#846204';
export const Accent675: string = '#7a5c04';
export const Accent700: string = '#725503';
export const Accent725: string = '#684e03';
export const Accent750: string = '#5f4803';
export const Accent775: string = '#564103';
export const Accent800: string = '#4c3a03';
export const Accent825: string = '#433403';
export const Accent850: string = '#392c03';
export const Accent875: string = '#2f2503';
export const Accent900: string = '#261e03';
export const Accent925: string = '#1e1600';
export const Accent950: string = '#150e00';
export const Accent975: string = '#0b0700';
export const Neutral25: string = '#f7faf7';
export const Neutral50: string = '#f0f5f0';
export const Neutral75: string = '#e8efe9';
export const Neutral100: string = '#e0e9e1';
export const Neutral125: string = '#d8e3da';
export const Neutral150: string = '#d1ded2';
export const Neutral175: string = '#c9d8cc';
export const Neutral200: string = '#c3d3c4';
export const Neutral225: string = '#bbcdbe';
export const Neutral250: string = '#b4c7b7';
export const Neutral275: string = '#adc1b0';
export const Neutral300: string = '#a6bbaa';
export const Neutral325: string = '#9eb5a3';
export const Neutral350: string = '#97af9d';
export const Neutral375: string = '#91a996';
export const Neutral400: string = '#8aa38f';
export const Neutral425: string = '#839d89';
export const Neutral450: string = '#7d9783';
export const Neutral475: string = '#76907d';
export const Neutral500: string = '#708a77';
export const Neutral525: string = '#6a8470';
export const Neutral550: string = '#637d6a';
export const Neutral575: string = '#5d7764';
export const Neutral600: string = '#57705f';
export const Neutral625: string = '#506959';
export const Neutral650: string = '#4c6352';
export const Neutral675: string = '#455c4c';
export const Neutral700: string = '#3f5547';
export const Neutral725: string = '#394e41';
export const Neutral750: string = '#34473b';
export const Neutral775: string = '#2e4135';
export const Neutral800: string = '#283a30';
export const Neutral825: string = '#233329';
export const Neutral850: string = '#1e2c24';
export const Neutral875: string = '#19251d';
export const Neutral900: string = '#131d18';
export const Neutral925: string = '#0f1612';
export const Neutral950: string = '#090f0b';
export const Neutral975: string = '#040706';
export const AlphaPrimary25: string = 'rgba(11, 244, 128, 0.0700)';
export const AlphaPrimary50: string = 'rgba(13, 248, 134, 0.1400)';
export const AlphaPrimary75: string = 'rgba(5, 245, 129, 0.2000)';
export const AlphaPrimary100: string = 'rgba(5, 240, 126, 0.2600)';
export const AlphaPrimary125: string = 'rgba(5, 235, 128, 0.3200)';
export const AlphaPrimary150: string = 'rgba(1, 233, 125, 0.3700)';
export const AlphaPrimary175: string = 'rgba(4, 231, 121, 0.4300)';
export const AlphaPrimary200: string = 'rgba(1, 228, 119, 0.4800)';
export const AlphaAccent25: string = 'rgba(244, 197, 11, 0.0900)';
export const AlphaAccent50: string = 'rgba(242, 188, 7, 0.1700)';
export const AlphaAccent75: string = 'rgba(237, 191, 7, 0.2500)';
export const AlphaAccent100: string = 'rgba(237, 190, 2, 0.3200)';
export const AlphaAccent125: string = 'rgba(233, 187, 1, 0.3900)';
export const AlphaAccent150: string = 'rgba(231, 189, 4, 0.4600)';
export const AlphaAccent175: string = 'rgba(228, 187, 1, 0.5200)';
export const AlphaAccent200: string = 'rgba(227, 182, 2, 0.5800)';
export const AlphaNeutral25: string = 'rgba(29, 129, 79, 0.0400)';
export const AlphaNeutral50: string = 'rgba(12, 111, 68, 0.0700)';
export const AlphaNeutral75: string = 'rgba(5, 97, 56, 0.1000)';
export const AlphaNeutral100: string = 'rgba(12, 106, 62, 0.1400)';
export const AlphaNeutral125: string = 'rgba(8, 94, 60, 0.1700)';
export const AlphaNeutral150: string = 'rgba(5, 97, 56, 0.2000)';
export const AlphaNeutral175: string = 'rgba(3, 89, 55, 0.2300)';
export const AlphaNeutral200: string = 'rgba(5, 92, 51, 0.2600)';
export const AlphaNeutral225: string = 'rgba(3, 89, 53, 0.2900)';
export const AlphaNeutral250: string = 'rgba(2, 85, 53, 0.3200)';
export const AlphaNeutral275: string = 'rgba(4, 83, 50, 0.3500)';
export const AlphaNeutral300: string = 'rgba(5, 81, 51, 0.3800)';
export const AlphaNeutral325: string = 'rgba(4, 78, 51, 0.4100)';
export const AlphaNeutral350: string = 'rgba(5, 77, 52, 0.4400)';
export const AlphaNeutral375: string = 'rgba(1, 70, 44, 0.4600)';
export const AlphaNeutral400: string = 'rgba(2, 69, 46, 0.4900)';
export const AlphaNeutral425: string = 'rgba(3, 69, 46, 0.5200)';
export const AlphaNeutral450: string = 'rgba(1, 65, 43, 0.5400)';
export const AlphaNeutral475: string = 'rgba(2, 64, 45, 0.5700)';
export const AlphaNeutral500: string = 'rgba(1, 60, 41, 0.5900)';
export const AlphaNeutral525: string = 'rgba(2, 59, 40, 0.6200)';
export const AlphaNeutral550: string = 'rgba(0, 51, 35, 0.6400)';
export const AlphaNeutral575: string = 'rgba(3, 53, 36, 0.6700)';
export const AlphaNeutral600: string = 'rgba(1, 50, 36, 0.6900)';
export const AlphaNeutral625: string = 'rgba(0, 46, 33, 0.7100)';
export const AlphaNeutral650: string = 'rgba(0, 41, 27, 0.7300)';
export const AlphaNeutral675: string = 'rgba(2, 44, 31, 0.7600)';
export const AlphaNeutral700: string = 'rgba(1, 40, 29, 0.7800)';
export const AlphaNeutral725: string = 'rgba(1, 35, 26, 0.8000)';
export const AlphaNeutral750: string = 'rgba(1, 35, 25, 0.8200)';
export const AlphaNeutral775: string = 'rgba(0, 31, 23, 0.8400)';
export const AlphaNeutral800: string = 'rgba(0, 25, 20, 0.8600)';
export const AlphaNeutral825: string = 'rgba(0, 25, 20, 0.8800)';
export const AlphaNeutral850: string = 'rgba(2, 23, 18, 0.9000)';
export const AlphaNeutral875: string = 'rgba(2, 18, 14, 0.9200)';
export const AlphaNeutral900: string = 'rgba(2, 14, 11, 0.9400)';
export const AlphaNeutral925: string = 'rgba(0, 10, 8, 0.9500)';
export const AlphaNeutral950: string = 'rgba(0, 10, 8, 0.9700)';
export const AlphaNeutral975: string = 'rgba(1, 4, 4, 0.9900)';
export const FillPrimaryL200: string = '#45dc70';
export const FillPrimaryL175: string = '#41d967';
export const FillPrimaryL150: string = '#41d560';
export const FillPrimaryL125: string = '#32d25d';
export const FillPrimaryL100: string = '#28ce58';
export const FillPrimaryL75: string = '#02cb57';
export const FillPrimaryL50: string = '#01c652';
export const FillPrimaryL25: string = '#00c24c';
export const FillPrimaryMain: string = '#01bd49';
export const FillPrimaryD25: string = '#00b544';
export const FillPrimaryD50: string = '#01ad41';
export const FillPrimaryD75: string = '#01a53e';
export const FillPrimaryD100: string = '#019d3b';
export const FillPrimaryD125: string = '#009439';
export const FillPrimaryD150: string = '#008b35';
export const FillPrimaryD175: string = '#018233';
export const FillPrimaryD200: string = '#007830';
export const FillAccentL200: string = '#fff4d1';
export const FillAccentL175: string = '#ffeebb';
export const FillAccentL150: string = '#ffe8a6';
export const FillAccentL125: string = '#ffe392';
export const FillAccentL100: string = '#ffdc80';
export const FillAccentL75: string = '#ffd670';
export const FillAccentL50: string = '#ffcf62';
export const FillAccentL25: string = '#fec946';
export const FillAccentMain: string = '#fdc325';
export const FillAccentD25: string = '#f7bd12';
export const FillAccentD50: string = '#f1b70a';
export const FillAccentD75: string = '#eab109';
export const FillAccentD100: string = '#e4ab0a';
export const FillAccentD125: string = '#dca509';
export const FillAccentD150: string = '#d6a008';
export const FillAccentD175: string = '#ce9a08';
export const FillAccentD200: string = '#c69407';
export const FillNeutralL200: string = '#3f5547';
export const FillNeutralL175: string = '#394e41';
export const FillNeutralL150: string = '#34473b';
export const FillNeutralL125: string = '#2e4135';
export const FillNeutralL100: string = '#283a30';
export const FillNeutralL75: string = '#233329';
export const FillNeutralL50: string = '#1e2c24';
export const FillNeutralL25: string = '#19251d';
export const FillNeutralMain: string = '#131d18';
export const OverPrimary25: string = '#00b544';
export const OverPrimary50: string = '#01ad41';
export const OverPrimary75: string = '#01a53e';
export const OverPrimary100: string = '#019d3b';
export const OverPrimary125: string = '#009439';
export const OverPrimary150: string = '#008b35';
export const OverPrimary175: string = '#018233';
export const OverPrimary200: string = '#007830';
export const OverPrimaryMax: string = '#ffffff';
export const OverAccent25: string = '#f7bd12';
export const OverAccent50: string = '#f1b70a';
export const OverAccent75: string = '#eab109';
export const OverAccent100: string = '#e4ab0a';
export const OverAccent125: string = '#dca509';
export const OverAccent150: string = '#d6a008';
export const OverAccent175: string = '#ce9a08';
export const OverAccent200: string = '#c69407';
export const OverAccentMax: string = '#000000';
export const OverNeutral25: string = '#19251d';
export const OverNeutral50: string = '#1e2c24';
export const OverNeutral75: string = '#233329';
export const OverNeutral100: string = '#283a30';
export const OverNeutral125: string = '#2e4135';
export const OverNeutral150: string = '#34473b';
export const OverNeutral175: string = '#394e41';
export const OverNeutral200: string = '#3f5547';
export const OverNeutralMax: string = '#ffffff';
export const TypoPrimaryL200: string = '#41d560';
export const TypoPrimaryL100: string = '#01c652';
export const TypoPrimaryMain: string = '#01ad41';
export const TypoPrimaryD100: string = '#008b35';
export const TypoPrimaryD200: string = '#006529';
export const TypoAccentL200: string = '#eab109';
export const TypoAccentL100: string = '#ce9a08';
export const TypoAccentMain: string = '#af8206';
export const TypoAccentD100: string = '#8d6902';
export const TypoAccentD200: string = '#684e03';
export const TypoNeutral100: string = '#000000';
export const TypoNeutral200: string = '#455c4c';
export const TypoNeutral300: string = '#637d6a';
export const TypoNeutral400: string = '#839d89';
export const TypoNeutral500: string = '#9eb5a3';
export const TypoNeutralContrast: string = '#ffffff';
export const Gray25: string = '#f8f9f8';
export const Gray50: string = '#f2f4f2';
export const Gray75: string = '#ecedeb';
export const Gray100: string = '#e6e7e5';
export const Gray125: string = '#dfe1df';
export const Gray150: string = '#d9dbda';
export const Gray175: string = '#d2d5d3';
export const Gray200: string = '#ccd0cd';
export const Gray225: string = '#c5cac5';
export const Gray250: string = '#bdc4be';
export const Gray275: string = '#b7beb8';
export const Gray300: string = '#b0b8b1';
export const Gray325: string = '#a9b1ab';
export const Gray350: string = '#a2aca4';
export const Gray375: string = '#9ba69d';
export const Gray400: string = '#94a097';
export const Gray425: string = '#8d9a90';
export const Gray450: string = '#87948a';
export const Gray475: string = '#808d83';
export const Gray500: string = '#7a877d';
export const Gray525: string = '#738176';
export const Gray550: string = '#6d7a70';
export const Gray575: string = '#67746a';
export const Gray600: string = '#616d64';
export const Gray625: string = '#5b665e';
export const Gray650: string = '#556058';
export const Gray675: string = '#4f5952';
export const Gray700: string = '#49524c';
export const Gray725: string = '#434c46';
export const Gray750: string = '#3d4540';
export const Gray775: string = '#373e3a';
export const Gray800: string = '#313734';
export const Gray825: string = '#2b302d';
export const Gray850: string = '#262a27';
export const Gray875: string = '#202321';
export const Gray900: string = '#1a1b19';
export const Gray925: string = '#131413';
export const Gray950: string = '#0c0e0c';
export const Gray975: string = '#050705';
