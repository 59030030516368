'use client';

import { createGlobalStyle, css } from 'styled-components';
import { resolveTypographyVariant } from '../theme';

const bodyMixin = css(({ theme }) => ({
  ...resolveTypographyVariant(theme.typography.body.m.paragraph),
  margin: 0,
  color: theme.palette.TypoNeutral100,
  backgroundColor: theme.palette.Neutral50,
}));

export const GlobalStyles = createGlobalStyle`
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    font-size: 62.5%;
  }
  
  *, *::before, *::after {
    box-sizing: inherit;
  }

  body {
    ${bodyMixin}
  }
`;
