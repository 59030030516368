'use client';

import styled, { css } from 'styled-components';
import { type TButtonProps } from './types';

interface TRootProps {
  $size: NonNullable<TButtonProps['size']>;
  $variant: NonNullable<TButtonProps['variant']>;
  $color: NonNullable<TButtonProps['color']>;
}

export const buttonMixin = css<TRootProps>(
  ({ theme, $size, $variant, $color }) => ({
    display: 'inline-block',
    textDecoration: 'none',
    textAlign: 'center',
    border: 0,
    backgroundColor: 'transparent',
    appearance: 'none',
    cursor: 'pointer',
    fontFamily: 'inherit',
    whiteSpace: 'nowrap',

    ...($size === 'xs' && {
      padding: '0.6rem 0.8rem',
      fontSize: '1.4rem',
      lineHeight: 2 / 1.4,
      fontWeight: 600,
      letterSpacing: '-0.007rem',
      borderRadius: '0.8rem',
    }),

    ...($size === 'sm' && {
      padding: '1rem 1.2rem',
      fontSize: '1.6rem',
      lineHeight: 2 / 1.6,
      fontWeight: 600,
      letterSpacing: '-0.008rem',
      borderRadius: '1rem',
    }),

    ...($size === 'md' && {
      padding: '1.2rem 1.6rem',
      fontSize: '1.6rem',
      lineHeight: 2.4 / 1.6,
      fontWeight: 600,
      letterSpacing: '-0.016rem',
      borderRadius: '1.2rem',
    }),

    ...($size === 'lg' && {
      padding: '1.6rem 2rem',
      fontSize: '1.6rem',
      lineHeight: 2.4 / 1.6,
      fontWeight: 600,
      letterSpacing: '-0.016rem',
      borderRadius: '1.2rem',
    }),

    ...($size === 'xl' && {
      padding: '2rem 2.4rem',
      fontSize: '1.8rem',
      lineHeight: 2.4 / 1.8,
      fontWeight: 600,
      letterSpacing: '-0.018rem',
      borderRadius: '1.6rem',
    }),

    ...($variant === 'fill' && {
      ...($color === 'primary' && {
        background: `linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%), ${theme.palette.Primary525}`,
        color: theme.palette.TypoNeutralContrast,
        borderRadius: '1.2rem',
        border: '1px solid rgba(0, 0, 0, 0.2)',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
      }),

      ...($color === 'accent' && {
        background: theme.palette.FillAccentMain,
        color: theme.palette.TypoNeutral100,
      }),
    }),

    ...($variant === 'text' && {
      ...($color === 'neutral' && {
        background: 'transparent',
        color: theme.palette.TypoNeutral100,
      }),
    }),

    '&:hover': {
      ...($variant === 'fill' && {
        ...($color === 'primary' && {
          backgroundColor: theme.palette.FillPrimaryD150,
        }),

        ...($color === 'accent' && {
          backgroundColor: theme.palette.FillAccentL50,
        }),
      }),

      ...($variant === 'text' && {
        ...($color === 'neutral' && {
          color: theme.palette.TypoNeutral100,
        }),
      }),
    },

    '&:active': {
      ...($variant === 'fill' && {
        ...($color === 'primary' && {
          backgroundColor: theme.palette.FillPrimaryD200,
        }),

        ...($color === 'accent' && {
          backgroundColor: theme.palette.FillAccentL100,
        }),
      }),

      ...($variant === 'text' && {
        ...($color === 'neutral' && {
          color: theme.palette.TypoNeutral100,
        }),
      }),
    },
  }),
);

export const LinkRoot = styled.a<TRootProps>(() => buttonMixin);

export const ButtonRoot = styled.button<TRootProps>(() => buttonMixin);

export const Inner = styled.div`
  display: flex;
  gap: 0.8rem;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
`;
