import { type DefaultTheme } from 'styled-components';
import { palette } from '@debt-relief/design-tokens';
import { interFont } from './fonts';

export const theme: DefaultTheme = {
  palette,
  typography: {
    display: {
      xs: {
        fontFamily: 'ivypresto-headline, serif',
        fontWeight: 400,
        fontSize: '3.3rem',
        lineHeight: 4 / 3.3,
        letterSpacing: '-0.033rem',
      },
      s: {
        fontFamily: 'ivypresto-headline, serif',
        fontWeight: 400,
        fontSize: '3.8rem',
        lineHeight: 4.8 / 3.8,
        letterSpacing: '-0.038rem',
      },
      m: {
        fontFamily: 'ivypresto-headline, serif',
        fontWeight: 400,
        fontSize: '4.4rem',
        lineHeight: 5.2 / 4.4,
        letterSpacing: '-0.044rem',
      },
      l: {
        fontFamily: 'ivypresto-headline, serif',
        fontWeight: 400,
        fontSize: '4.9rem',
        lineHeight: 5.6 / 4.9,
        letterSpacing: '-0.049rem',
      },
      xl: {
        fontFamily: 'ivypresto-headline, serif',
        fontWeight: 400,
        fontSize: '5.5rem',
        lineHeight: 6 / 5.5,
        letterSpacing: '-0.055rem',
      },
      xxl: {
        fontFamily: 'ivypresto-headline, serif',
        fontWeight: 400,
        fontSize: '6rem',
        lineHeight: 6.8 / 6,
        letterSpacing: '-0.06rem',
      },
      xxxl: {
        fontFamily: 'ivypresto-headline, serif',
        fontWeight: 400,
        fontSize: '6.6rem',
        lineHeight: 7.2 / 6.6,
        letterSpacing: '-0.066rem',
      },
    },
    headline: {
      xs: {
        fontFamily: interFont.style.fontFamily,
        fontWeight: 600,
        fontSize: '1.6rem',
        lineHeight: 2 / 1.6,
        letterSpacing: '-0.016rem',
      },
      s: {
        fontFamily: interFont.style.fontFamily,
        fontWeight: 600,
        fontSize: '1.8rem',
        lineHeight: 2.4 / 1.8,
        letterSpacing: '-0.018rem',
      },
      m: {
        fontFamily: interFont.style.fontFamily,
        fontWeight: 600,
        fontSize: '2.2rem',
        lineHeight: 2.8 / 2.2,
        letterSpacing: '-0.022rem',
      },
      l: {
        fontFamily: interFont.style.fontFamily,
        fontWeight: 600,
        fontSize: '2.7rem',
        lineHeight: 3.6 / 2.7,
        letterSpacing: '-0.027rem',
      },
      xl: {
        fontFamily: interFont.style.fontFamily,
        fontWeight: 600,
        fontSize: '3.3rem',
        lineHeight: 4 / 3.3,
        letterSpacing: '-0.033rem',
      },
    },
    body: {
      s: {
        paragraph: {
          fontFamily: interFont.style.fontFamily,
          fontWeight: 400,
          fontSize: '1.4rem',
          lineHeight: 2 / 1.4,
          letterSpacing: '-0.007rem',
        },
        tight: {
          fontFamily: interFont.style.fontFamily,
          fontWeight: 400,
          fontSize: '1.4rem',
          lineHeight: 1.6 / 1.4,
          letterSpacing: '-0.007rem',
        },
      },
      m: {
        paragraph: {
          fontFamily: interFont.style.fontFamily,
          fontWeight: 400,
          fontSize: '1.6rem',
          lineHeight: 2.4 / 1.6,
          letterSpacing: '-0.016rem',
        },
        tight: {
          fontFamily: interFont.style.fontFamily,
          fontWeight: 400,
          fontSize: '1.8rem',
          lineHeight: 2 / 1.8,
          letterSpacing: '-0.09rem',
        },
      },
      l: {
        paragraph: {
          fontFamily: interFont.style.fontFamily,
          fontWeight: 400,
          fontSize: '1.8rem',
          lineHeight: 2.8 / 1.8,
          letterSpacing: '-0.018rem',
        },
        tight: {
          fontFamily: interFont.style.fontFamily,
          fontWeight: 400,
          fontSize: '1.8rem',
          lineHeight: 2.4 / 1.8,
          letterSpacing: '-0.018rem',
        },
      },
    },
  },
  breakpoints: {
    desktop: '1280px',
    tablet: '960px',
    mobile: '475px',
  },
};
