'use client';

import styled from 'styled-components';
import { type TSectionProps } from './types';

interface TRootProps {
  $paddings?: TSectionProps['paddings'];
}

export const Root = styled.section<TRootProps>(({ theme, $paddings }) => ({
  position: 'relative',
  maxWidth: '121.2rem',
  margin: '0 auto',
  padding: '0 1.6rem',

  ...($paddings === 'sm' && {
    paddingTop: '2rem',
    paddingBottom: '2rem',
  }),

  ...($paddings === 'md' && {
    paddingTop: '4rem',
    paddingBottom: '4rem',
  }),

  ...($paddings === 'lg' && {
    paddingTop: '8rem',
    paddingBottom: '8rem',
  }),

  [`@media (max-width: ${theme.breakpoints.tablet})`]: {
    ...($paddings === 'sm' && {
      paddingTop: '1.6rem',
      paddingBottom: '1.6rem',
    }),

    ...($paddings === 'md' && {
      paddingTop: '3.2rem',
      paddingBottom: '3.2rem',
    }),

    ...($paddings === 'lg' && {
      paddingTop: '6.4rem',
      paddingBottom: '6.4rem',
    }),
  },
}));
