import {
  type TBreakpoint,
  type TThemeTypographySimpleVariant,
  type TThemeTypographyVariant,
} from './types';

export const isTypographySimpleVariant = (
  variant: TThemeTypographyVariant,
): variant is TThemeTypographySimpleVariant => 'fontFamily' in variant;

export const resolveTypographyVariant = (
  variant: TThemeTypographyVariant,
  breakpoint: TBreakpoint = 'mobile',
): TThemeTypographySimpleVariant => {
  if (isTypographySimpleVariant(variant)) {
    return variant;
  }

  return variant[breakpoint];
};
