export {
  type TTheme,
  type TThemeTypographySimpleVariant,
  type TThemeTypographyResponsiveVariant,
  type TThemeTypographyVariant,
} from './types';
export { interFont } from './fonts';
export { theme } from './theme';
export {
  isTypographySimpleVariant,
  resolveTypographyVariant,
} from './typography';
